import {
  formatDistanceToNowStrict, addSeconds, parseISO, format, isWithinInterval, subMinutes, addMinutes,
  getUnixTime, startOfWeek, subMonths, addDays, differenceInHours
} from "date-fns";
import {
  formatInTimeZone
} from "date-fns-tz";
import { ru, uk, enUS, es } from "date-fns/locale/index.js";

const availableLocales: { [key: string]: Locale } = {
  ru: ru,
  uk: uk,
  en: enUS,
  es: es,
}

const deviceTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export function dateFormatter(locale: string) {
  function formatTimer(seconds: number) {
    const endDate = addSeconds(new Date(), seconds);
    return formatDistanceToNowStrict(endDate, {
      unit: "second",
      locale: availableLocales[locale],
      addSuffix: true,
    })
  }

  function formatHM(dateTime: string|Date, tz: string|undefined = undefined) {
    if (typeof dateTime === 'string') {
      dateTime = parseISO(dateTime);
    }
    const timeZone = tz ?? deviceTimeZone;

    return formatInTimeZone(dateTime, timeZone, 'HH:mm', {
      locale: availableLocales[locale],
    })
  }

  function formatDMY(dateTime: string|Date, tz: string|undefined = undefined) {
    if (typeof dateTime === 'string') {
      dateTime = parseISO(dateTime);
    }
    const timeZone = tz ?? deviceTimeZone;

    return formatInTimeZone(dateTime, timeZone, 'dd-MM-yyy', {
      locale: availableLocales[locale],
    })
  }

  function formatMY(dateTime: string|Date, tz: string|undefined = undefined) {
    if (typeof dateTime === 'string') {
      dateTime = parseISO(dateTime);
    }
    const timeZone = tz ?? deviceTimeZone;

    return formatInTimeZone(dateTime, timeZone, 'MMM yyyy', {
      locale: availableLocales[locale],
    })
  }

  function formatBlogPost(dateTime: string|Date, tz: string|undefined = undefined) {
    if (typeof dateTime === 'string') {
      dateTime = parseISO(dateTime);
    }
    const timeZone = tz ?? deviceTimeZone;

    return formatInTimeZone(dateTime, timeZone, 'do MMM yyyy', {
      locale: availableLocales[locale],
    })
  }

  function formatSessionDateTime(dateTime: string|Date, tz: string|undefined = undefined) {
    if (typeof dateTime === 'string') {
      dateTime = parseISO(dateTime);
    }
    const timeZone = tz ?? deviceTimeZone;

    return formatInTimeZone(dateTime, timeZone, 'do MMM HH:mm', {
      locale: availableLocales[locale],
    })
  }

  function formatFinanceDate(dateTime: string|Date, tz: string|undefined = undefined) {
    if (typeof dateTime === 'string') {
      dateTime = parseISO(dateTime);
    }
    const timeZone = tz ?? deviceTimeZone;

    return formatInTimeZone(dateTime, timeZone, 'dd/LL/Y HH:mm:ss', {
      locale: availableLocales[locale],
    })
  }

  function parseFromIso8601Period(iso8601Period: string): {startAt, endAt} {
    const [startIsoDate, _, endIsoDate] = iso8601Period.split('/');
    return {startAt: parseISO(startIsoDate), endAt: parseISO(endIsoDate)};
  }

  function formatChatMessageTime(time: string|Date) {
    if(typeof time === 'string') {
      time = parseISO(time)
    }

    return format(time, 'dd MMMM HH:mm', {
      locale: availableLocales[locale],
    })
  }

  function isBetween15MinuteRange(iso8601Period: string): boolean {
    const timeSlot = parseFromIso8601Period(iso8601Period);
    return isWithinInterval(new Date(), {
      start: subMinutes(timeSlot.startAt, 15),
      end: addMinutes(timeSlot.endAt, 15),
    });
  }

  function in24HoursRange(iso8601: string): boolean {
    return differenceInHours(parseISO(iso8601), new Date()) < 24;
  }

  function formatCheckoutCalendarMonth(date: Date|string, tz: string|undefined = undefined) {
    if (typeof date === 'string') {
      date = parseISO(date);
    }
    const timeZone = tz ?? deviceTimeZone;

    return formatInTimeZone(date, timeZone, 'MMMM', {
      locale: availableLocales[locale],
    })
  }

  function formatCheckoutCalendarDay(date: Date|string, tz: string|undefined = undefined) {
    if (typeof date === 'string') {
      date = parseISO(date);
    }
    const timeZone = tz ?? deviceTimeZone;

    return formatInTimeZone(date, timeZone, 'd', {
      locale: availableLocales[locale],
    })
  }

  function formatCheckoutCalendarWeekdayName(date: Date|string, tz: string|undefined = undefined) {
    if (typeof date === 'string') {
      date = parseISO(date);
    }
    const timeZone = tz ?? deviceTimeZone;

    return formatInTimeZone(date, timeZone, 'EEEE', {
      locale: availableLocales[locale],
    })
  }

  function getTimestamp(date: Date|string) {
    if (typeof date === 'string') {
      date = parseISO(date);
    }

    return getUnixTime(date);
  }

  function formatCalendarMonthName(date: Date|string) {
    if (typeof date === 'string') {
      date = parseISO(date);
    }

    return format(date, 'LLLL, yyyy', {
      locale: availableLocales[locale],
    })
  }

  function formatCalendarDayName(date: Date) {
    return format(date, 'do MMMM yyyy', {
      locale: availableLocales[locale],
    })
  }

  function formatCalendarDateRange(startDate: Date, endDate: Date) {
    const formattedStartDate = format(startDate, 'do MMMM yyyy', {locale: availableLocales[locale]});
    const formattedEndDate = format(endDate, 'do MMMM yyyy', {locale: availableLocales[locale]});
    return `${formattedStartDate} - ${formattedEndDate}`;
  }

  function getNameDayOfWeek(dayNum: number) {
    const firstDOW = startOfWeek(new Date())
    return format(addDays(firstDOW, dayNum), 'EEEEEE', {
      weekStartsOn: 1, locale: availableLocales[locale]
    });
  }

  function getLastMonths(countMonth: number = 12) {
    let now = new Date();
    const list = [
      {label: formatMY(now), value: now.getUTCFullYear()+'-'+(now.getUTCMonth()+1)}
    ];
    for (let i = 0; i < countMonth; i++) {
      now = subMonths(now, 1)
      list.push({label: formatMY(now), value: now.getUTCFullYear()+'-'+(now.getUTCMonth()+1)});
    }
    return list;
  }

  return {
    deviceTimeZone,

    formatTimer,
    formatSessionDateTime,
    formatFinanceDate,
    formatHM,
    formatDMY,
    formatMY,
    formatBlogPost,
    parseFromIso8601Period,
    formatChatMessageTime,
    isBetween15MinuteRange,
    in24HoursRange,
    formatCheckoutCalendarMonth,
    formatCheckoutCalendarDay,
    formatCheckoutCalendarWeekdayName,
    getTimestamp,
    formatCalendarMonthName,
    formatCalendarDayName,
    formatCalendarDateRange,
    getNameDayOfWeek,
    getLastMonths,
  }
}
